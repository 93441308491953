import type {z} from 'zod'
import type {Cluster, Marker} from '@googlemaps/markerclusterer'
import {MarkerClusterer} from '@googlemaps/markerclusterer'
import {convertMilesToMeters} from '@/_new-code/utilities/distance'
import {logError} from '@/services/client-logger'
import {
	FILL_OPACITY_MAP_CIRCLE,
	MAP_TYPE,
	MARKER_COLOR_MAP_CIRCLE,
	STROKE_OPACITY_MAP_CIRCLE,
	STROKE_WEIGHT_MAP_CIRCLE,
} from '../../utils/constants'
import {getClusterSvgString} from '../../utils/assets-helper'
import type {
	AdvancedMarkerElementType,
	CaseData,
	GroupedCases,
	SearchLocation,
} from './types'

export class MapUtilities {
	private map: google.maps.Map | null

	constructor() {
		this.map = null
	}
	static getMapType(mapType: string): z.infer<typeof MAP_TYPE> | null {
		const parseResult = MAP_TYPE.safeParse(mapType)
		if (!parseResult.success) {
			return null
		}
		return parseResult.data
	}

	static sortCasesByPostCode(cases: CaseData[]): GroupedCases {
		return cases.reduce<GroupedCases>((prevCase, currCase) => {
			const tmpCase = {...prevCase}
			const postCode = currCase.location.postalCode
			if (postCode) {
				if (!tmpCase[postCode]) {
					tmpCase[postCode] = []
				}
				tmpCase[postCode]?.push(currCase)
			}
			return tmpCase
		}, {})
	}

	static async createNewMarkerCluster(
		map: google.maps.Map | null | undefined,
		markers: AdvancedMarkerElementType[] | undefined
	): Promise<MarkerClusterer> {
		const parser = new DOMParser()
		const {AdvancedMarkerElement} = (await google.maps.importLibrary(
			'marker'
		)) as google.maps.MarkerLibrary
		return new MarkerClusterer({
			map,
			markers,
			renderer: {
				render: (cluster: Cluster) => {
					type MarkerOrNumber =
						| AdvancedMarkerElementType
						| Marker
						| number

					const clusterCount =
						cluster.markers?.reduce(
							(a: MarkerOrNumber, b: MarkerOrNumber) => {
								// If both `a` and `b` are objects and have `caseCount`
								if (
									typeof a === 'object' &&
									'caseCount' in a &&
									typeof a.caseCount === 'number' &&
									typeof b === 'object' &&
									'caseCount' in b &&
									typeof b.caseCount === 'number'
								) {
									return a.caseCount + b.caseCount
								}

								// If `a` is a number and `b` has `caseCount`
								if (
									typeof a === 'number' &&
									typeof b === 'object' &&
									'caseCount' in b &&
									typeof b.caseCount === 'number'
								) {
									return a + b.caseCount
								}

								// If `a` has `caseCount` and `b` is a number
								if (
									typeof a === 'object' &&
									'caseCount' in a &&
									typeof a.caseCount === 'number' &&
									typeof b === 'number'
								) {
									return a.caseCount + b
								}

								return a // Default return value if none of the conditions are met
							},
							0
						) || 0

					const clusterSvgString = getClusterSvgString(
						MARKER_COLOR_MAP_CIRCLE,
						clusterCount as number
					)
					const clusterSvg = parser.parseFromString(
						clusterSvgString,
						'image/svg+xml'
					).documentElement
					return new AdvancedMarkerElement({
						position: cluster.position,
						map,
						content: clusterSvg,
					})
				},
			},
		})
	}

	static getMapCircle(
		map: google.maps.Map | null | undefined,
		center: SearchLocation | null,
		radiusInMiles: number
	): google.maps.Circle {
		return new window.google.maps.Circle({
			map,
			center,
			radius: convertMilesToMeters(radiusInMiles),
			strokeColor: MARKER_COLOR_MAP_CIRCLE,
			strokeOpacity: STROKE_OPACITY_MAP_CIRCLE,
			strokeWeight: STROKE_WEIGHT_MAP_CIRCLE,
			fillColor: MARKER_COLOR_MAP_CIRCLE,
			fillOpacity: FILL_OPACITY_MAP_CIRCLE,
		})
	}

	static zoomToPlace(
		map: google.maps.Map,
		geocoder: google.maps.Geocoder,
		placeId: string,
		isMobile: boolean
	): boolean {
		geocoder
			.geocode({placeId})
			.then(({results}: {results: google.maps.GeocoderResult[]}) => {
				const bound = results[0]?.geometry.viewport || null
				const padding = isMobile ? 55 : 155
				if (bound) map.fitBounds(bound, padding)
			})
			.catch(() => {
				logError(`Could not zoom to the place ID${placeId}`)
				return true
			})
		return false
	}
}
